<template>
  <v-container>
    <v-row class="mb-4">
      <h2>ELN (In-Lab) Original Investigation - Plan</h2>
    </v-row>
    <v-form>
      <p class="mb-4">
        A plan must be made for the original investigation that will be done next week.
      </p>

      <p class="mb-3">1. What is the goal of the original investigation?</p>

      <p v-for="option in optionsQ1" :key="option.value" class="mb-0 mt-0">
        <v-checkbox
          v-model="inputs.question1Values"
          class="ml-6 mb-n4 mt-0"
          :label="option.text"
          :value="option.value"
        ></v-checkbox>
      </p>

      <p class="mb-4 mt-5" v-for="question in questions" :key="question.inputName">
        {{ question.text }} (<b>Maximum character count</b>: {{ question.maxInputLength }})
      </p>

      <s-textarea
        v-model="inputs.question2Value"
        class="mb-4"
        outlined
        rows="12"
        :counter="true"
        :maxlength="6000"
      />
    </v-form>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'UCIrvineChem1LCELN2OIPlan',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Values: [],
        question2Value: null,
      },
      optionsQ1: [
        {
          text: 'Determine what dyes are in the Gatorade.',
          value: 'answer1',
        },
        {
          text: "Create a Beer's Law plot using Gatorade.",
          value: 'answer2',
        },
        {
          text: 'Determine the dye concentration in Gatorade.',
          value: 'answer3',
        },
      ],
      questions: [
        {
          text: '2. What method will you use in the original investigation? Provide 3 to 6 bulleted procedural steps describing how you will use glassware, equipment, and instrumentation. Additionally, detail the data acquired during fundamental skills and propose the variables/data you will need to collect.',
          inputName: 'question2Value',
          maxInputLength: 6000,
        },
      ],
    };
  },
};
</script>
